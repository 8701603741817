import React from 'react';

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property vallue called `fill`. `fill` is useful
 * when you want to change your logo depending on the theme you are on.
 */
export default function Logo({ fill }) {
	console.log(fill);
	return (
		<svg version="1.1"
			width="100%" height="100px" viewBox="0 0 841.9 392.3"
		>
			<g id="XMLID_1_">
				<path id="XMLID_18_" fill={fill} d="M195.7,154.1c4.6,2.8,8.2,6.6,10.8,11.2c2.6,4.6,3.9,10,3.9,16.3c0,7.8-1.5,15.1-4.6,21.7
		c-3.1,6.6-7.5,12.3-13.1,17c-5.6,4.7-12.1,8.3-19.6,10.8c-7.4,2.5-15.6,3.7-24.6,3.7h-45.8l1.1-4.5c1,0,2.6-0.2,4.6-0.4
		c2-0.2,3.6-0.5,4.5-0.8c1.6-0.5,2.8-1.4,3.7-2.5c0.8-1.1,1.4-2.4,1.8-3.8l14.2-61.4c0.2-0.9,0.4-1.6,0.4-2.2c0.1-0.6,0.1-1,0.1-1.3
		c0-1.7-0.9-3.1-2.6-4.1c-1.8-1-4.6-1.7-8.6-2.3l1.1-4.5h45c4.6,0,9.4,0.5,14.3,1.6C187.1,149.7,191.6,151.5,195.7,154.1z
		 M177.4,213.2c2.9-5,5.1-10.4,6.5-16.3c1.4-5.9,2.1-12,2.1-18.4c0-8-1.8-14.4-5.5-19.1c-3.6-4.7-9.1-7.1-16.4-7.1
		c-1.4,0-2.9,0-4.3,0.1c-1.4,0.1-2.6,0.1-3.4,0.2l-15.2,65.9c-0.2,0.6-0.3,1.4-0.4,2.3c-0.1,0.9-0.2,1.5-0.2,1.9
		c0,2.6,1,4.4,2.9,5.4c1.9,1,4.6,1.5,8.1,1.5c5.6,0,10.6-1.5,15-4.5C170.9,222,174.5,218.1,177.4,213.2z"/>
				<path id="XMLID_21_" fill={fill} d="M258.8,227.1h-0.5c-1.4,1.2-2.8,2.4-4.1,3.4c-1.3,1-2.9,2-4.9,3.1c-1.7,0.9-3.6,1.6-5.5,2.2
		c-1.9,0.6-4,0.9-6.3,0.9c-7.2,0-12.6-2.1-16.2-6.3c-3.6-4.2-5.4-10-5.4-17.3c0-5.6,1-10.9,3-15.9c2-4.9,4.7-9.3,8.1-13
		c3.4-3.7,7.4-6.6,12-8.8c4.6-2.2,9.4-3.2,14.5-3.2c3.7,0,7,0.4,9.8,1.3c2.8,0.9,5.1,2,6.8,3.5l19-3.9l0.9,1l-9.1,38.1
		c-0.3,1.3-0.7,2.9-1.1,4.8c-0.4,1.9-0.6,3.4-0.6,4.4c0,2.4,0.5,4,1.6,4.9c1.1,0.9,2.9,1.3,5.6,1.3c1.2,0,2.5-0.2,4.2-0.7
		c1.6-0.5,2.8-0.8,3.4-1.1l-1.4,5.6c-4.1,1.6-7.3,2.8-9.8,3.7c-2.5,0.8-5.3,1.3-8.5,1.3c-4.2,0-7.6-0.8-10.2-2.5
		C261.6,231.8,259.8,229.7,258.8,227.1z M266.2,187.8c0-0.9-0.1-2-0.4-3.2c-0.2-1.2-0.7-2.3-1.3-3.2c-0.7-1.1-1.7-1.9-2.9-2.4
		c-1.2-0.6-2.7-0.8-4.5-0.8c-2.7,0-5.2,1-7.5,2.9c-2.3,2-4.3,4.6-6.1,7.8c-1.7,3.2-3.1,7-4.1,11.2c-1,4.3-1.5,8.6-1.5,13.1
		c0,2,0.2,3.9,0.5,5.6c0.3,1.7,0.8,3.2,1.5,4.4c0.7,1.3,1.7,2.3,3,3c1.3,0.7,2.8,1,4.7,1c1.9,0,3.8-0.6,5.6-1.7
		c1.8-1.1,3.5-2.5,5-4.2L266.2,187.8z"/>
				<path id="XMLID_24_" fill={fill} d="M320.7,236.4c-5,0-8.9-1.2-11.9-3.6c-3-2.4-4.5-5.5-4.5-9.2c0-1.5,0.1-3,0.3-4.3
		c0.2-1.4,0.5-3.1,1-5.3l7.7-32.9H302l1.5-6.4H315l4.5-18.7h20l-4.3,18.7H352l-1.5,6.4h-16.7l-7.5,32.9c-0.3,1.2-0.6,2.6-0.8,4.2
		c-0.3,1.7-0.4,2.7-0.4,3.2c0,2.4,0.6,4,1.9,4.9c1.3,0.9,3.3,1.4,6,1.4c1.3,0,3-0.2,5.3-0.7c2.2-0.5,3.8-0.9,4.7-1.2l-1.4,5.6
		c-3.6,1.6-7.3,2.9-10.9,3.8C327,235.9,323.7,236.4,320.7,236.4z"/>
				<path id="XMLID_26_" fill={fill} d="M395.3,227.1h-0.5c-1.4,1.2-2.8,2.4-4.1,3.4c-1.3,1-2.9,2-4.9,3.1c-1.7,0.9-3.6,1.6-5.5,2.2
		c-1.9,0.6-4,0.9-6.3,0.9c-7.2,0-12.6-2.1-16.2-6.3c-3.6-4.2-5.4-10-5.4-17.3c0-5.6,1-10.9,3-15.9c2-4.9,4.7-9.3,8.1-13
		c3.4-3.7,7.4-6.6,12-8.8c4.6-2.2,9.4-3.2,14.5-3.2c3.7,0,7,0.4,9.8,1.3c2.8,0.9,5.1,2,6.8,3.5l19-3.9l0.9,1l-9.1,38.1
		c-0.3,1.3-0.7,2.9-1.1,4.8c-0.4,1.9-0.6,3.4-0.6,4.4c0,2.4,0.5,4,1.6,4.9c1.1,0.9,2.9,1.3,5.6,1.3c1.2,0,2.5-0.2,4.2-0.7
		c1.6-0.5,2.8-0.8,3.4-1.1l-1.4,5.6c-4.1,1.6-7.3,2.8-9.8,3.7c-2.5,0.8-5.3,1.3-8.5,1.3c-4.2,0-7.6-0.8-10.2-2.5
		C398.1,231.8,396.3,229.7,395.3,227.1z M402.7,187.8c0-0.9-0.1-2-0.4-3.2c-0.2-1.2-0.7-2.3-1.3-3.2c-0.7-1.1-1.7-1.9-2.9-2.4
		c-1.2-0.6-2.7-0.8-4.5-0.8c-2.7,0-5.2,1-7.5,2.9c-2.3,2-4.3,4.6-6.1,7.8c-1.7,3.2-3.1,7-4.1,11.2c-1,4.3-1.5,8.6-1.5,13.1
		c0,2,0.2,3.9,0.5,5.6c0.3,1.7,0.8,3.2,1.5,4.4c0.7,1.3,1.7,2.3,3,3c1.3,0.7,2.8,1,4.7,1c1.9,0,3.8-0.6,5.6-1.7
		c1.8-1.1,3.5-2.5,5-4.2L402.7,187.8z"/>
				<path id="XMLID_29_" fill={fill} d="M510.2,195.7c0,5.6-0.9,10.9-2.8,16c-1.9,5.1-4.6,9.4-8.2,13c-3.8,3.8-8.2,6.8-13.4,8.9
		c-5.2,2.1-10.9,3.2-17.2,3.2c-1.5,0-3.8-0.2-6.7-0.5c-3-0.3-5.6-0.9-8-1.7c-3-1-5.8-2.5-8.4-4.4c-2.6-1.9-4.9-4.4-6.9-7.4
		l15.5-66.7c0.2-0.7,0.3-1.4,0.4-2c0.1-0.6,0.1-1.1,0.1-1.5c0-1.4-0.3-2.5-0.8-3.4c-0.5-0.9-1.3-1.6-2.2-2.2c-1-0.5-2.1-0.9-3.4-1.1
		c-1.3-0.2-2.8-0.5-4.5-0.6l1.1-4.6l32.6-1.6l0.7,1l-9.3,40.1h0.4c0.9-0.8,2-1.6,3.1-2.4c1.1-0.8,2.6-1.7,4.5-2.7
		c1.4-0.7,3.2-1.4,5.5-2.1c2.3-0.6,4.4-1,6.2-1c7.2,0,12.6,2.1,16.2,6.3C508.4,182.6,510.2,188.3,510.2,195.7z M488,195.4
		c0-2-0.1-3.9-0.4-5.6c-0.3-1.7-0.8-3.2-1.5-4.4c-0.7-1.3-1.7-2.3-3-3c-1.3-0.7-2.8-1.1-4.7-1.1c-2.1,0-4.1,0.6-6,1.8
		c-1.9,1.2-3.6,2.5-5.1,4.1l-7.8,34.5c0,1,0.1,2,0.4,3c0.3,1,0.8,2.1,1.7,3.2c0.8,1.2,1.7,2,2.7,2.7c1,0.6,2.4,0.9,4.3,0.9
		c2.8,0,5.4-1,7.9-3.1c2.5-2.1,4.5-4.8,6.2-8.2c1.8-3.6,3.2-7.5,4.1-11.5C487.5,204.8,488,200.3,488,195.4z"/>
				<path id="XMLID_32_" fill={fill} d="M561.2,227.1h-0.5c-1.4,1.2-2.8,2.4-4.1,3.4s-2.9,2-4.9,3.1c-1.7,0.9-3.6,1.6-5.5,2.2
		c-1.9,0.6-4,0.9-6.3,0.9c-7.2,0-12.6-2.1-16.2-6.3c-3.6-4.2-5.4-10-5.4-17.3c0-5.6,1-10.9,3-15.9c2-4.9,4.7-9.3,8.1-13
		c3.4-3.7,7.4-6.6,12-8.8c4.6-2.2,9.4-3.2,14.5-3.2c3.7,0,7,0.4,9.8,1.3c2.8,0.9,5.1,2,6.8,3.5l19-3.9l0.9,1l-9.1,38.1
		c-0.3,1.3-0.7,2.9-1.1,4.8c-0.4,1.9-0.6,3.4-0.6,4.4c0,2.4,0.5,4,1.6,4.9c1.1,0.9,2.9,1.3,5.6,1.3c1.2,0,2.5-0.2,4.2-0.7
		c1.6-0.5,2.8-0.8,3.4-1.1l-1.4,5.6c-4.1,1.6-7.3,2.8-9.8,3.7c-2.5,0.8-5.3,1.3-8.5,1.3c-4.2,0-7.6-0.8-10.2-2.5
		C564,231.8,562.2,229.7,561.2,227.1z M568.6,187.8c0-0.9-0.1-2-0.4-3.2c-0.2-1.2-0.7-2.3-1.3-3.2c-0.7-1.1-1.7-1.9-2.9-2.4
		c-1.2-0.6-2.7-0.8-4.5-0.8c-2.7,0-5.2,1-7.5,2.9c-2.3,2-4.3,4.6-6.1,7.8c-1.7,3.2-3.1,7-4.1,11.2c-1,4.3-1.5,8.6-1.5,13.1
		c0,2,0.2,3.9,0.5,5.6c0.3,1.7,0.8,3.2,1.5,4.4c0.7,1.3,1.7,2.3,3,3c1.3,0.7,2.8,1,4.7,1c1.9,0,3.8-0.6,5.6-1.7
		c1.8-1.1,3.5-2.5,5-4.2L568.6,187.8z"/>
				<path id="XMLID_35_" fill={fill} d="M624.7,236.8c-5.2,0-9.5-0.4-12.9-1.3s-6.1-1.9-8.1-3.2c-2-1.3-3.4-2.7-4.1-4.2
		c-0.7-1.5-1.1-2.9-1.1-4.2c0-2.4,0.9-4.5,2.7-6.3c1.8-1.8,4.2-2.7,7.1-2.7c2.5,0,4.5,0.6,6.2,1.9c1.7,1.3,3.1,2.9,4.2,4.8
		c1,1.8,1.9,3.7,2.6,5.9c0.7,2.1,1.2,3.7,1.5,4.8c4.7,0,8.2-1,10.6-3c2.4-2,3.6-4.7,3.6-8.2c0-1.5-0.4-2.8-1.1-3.8
		c-0.7-1-1.7-1.9-2.9-2.7c-1.2-0.7-2.7-1.5-4.6-2.2c-1.9-0.7-3.7-1.4-5.5-2c-5-1.7-8.9-4-11.8-7c-2.9-3-4.4-6.7-4.4-11.2
		c0-2.6,0.6-5.1,1.8-7.5c1.2-2.3,3.1-4.5,5.7-6.5c2.6-1.9,5.8-3.4,9.7-4.5c3.9-1.1,8.5-1.7,13.7-1.7c3.1,0,6,0.2,8.7,0.6
		c2.7,0.4,5.2,1.1,7.5,2c2.2,1,3.9,2.3,5.1,3.9c1.2,1.6,1.8,3.5,1.8,5.8c0,2.3-0.9,4.2-2.6,5.8c-1.7,1.6-4,2.4-6.7,2.4
		c-2.4,0-4.4-0.6-6-1.9c-1.6-1.2-2.9-2.7-3.9-4.4c-1-1.7-1.8-3.5-2.4-5.4c-0.6-1.8-1.1-3.4-1.4-4.6c-2.9,0.2-5.5,1.2-7.9,3.1
		s-3.6,4.3-3.6,7.2c0,1.7,0.3,3.1,0.8,4.1c0.6,1,1.3,1.8,2.3,2.5c1.1,0.7,2.4,1.4,4.1,2.1c1.6,0.6,3.7,1.4,6.1,2.2
		c5.6,2,9.8,4.4,12.7,7.1c2.8,2.8,4.3,6.4,4.3,11c0,3-0.7,5.8-2.1,8.5c-1.4,2.7-3.4,5-5.9,6.8c-2.8,2-6.1,3.5-9.8,4.5
		C635.2,236.3,630.5,236.8,624.7,236.8z"/>
				<path id="XMLID_37_" fill={fill} d="M747.1,225.4l-1.4,5.4c-4.1,1.6-7.3,2.8-9.8,3.7c-2.4,0.8-5.3,1.3-8.5,1.3
		c-4.9,0-8.7-1.2-11.5-3.7c-2.7-2.5-4.1-5.5-4.1-9.1c0-1.2,0.1-2.4,0.2-3.8c0.1-1.4,0.5-3.3,1.1-5.8l4.4-16.5c0.5-1.8,0.8-3.4,1-4.8
		c0.2-1.4,0.3-2.6,0.3-3.5c0-2-0.5-3.5-1.4-4.6s-2.6-1.7-4.8-1.7c-1.4,0-3.4,0.5-6,1.6c-2.6,1.1-5.5,3.1-8.7,6.1l-10.3,44.7h-20.4
		l18.2-78.8c0.2-0.7,0.3-1.4,0.4-2c0.1-0.6,0.1-1.1,0.1-1.5c0-1.5-0.2-2.7-0.7-3.5c-0.5-0.9-1.2-1.6-2.2-2.1c-1-0.5-2.1-0.9-3.4-1.1
		c-1.3-0.2-2.8-0.4-4.5-0.6l1.1-4.6l32.6-1.6l0.7,1l-9.9,42.8h0.4c3.8-3.4,7.8-6,12-8c4.1-1.9,8.6-2.9,13.3-2.9
		c4.6,0,8.2,1.3,10.7,3.8c2.6,2.5,3.9,5.6,3.9,9.2c0,0.9-0.1,2.3-0.2,4c-0.1,1.7-0.5,3.5-1,5.5l-4.7,17.4c-0.4,1.4-0.8,3.1-1.1,5
		c-0.3,1.9-0.5,3.3-0.5,4.2c0,2.4,0.5,4,1.6,4.9c1.1,0.9,2.9,1.4,5.5,1.4c1.2,0,2.6-0.2,4.2-0.6
		C745.3,226.2,746.4,225.8,747.1,225.4z"/>
			</g>
			<line id="XMLID_2_" fill="none" stroke={fill} strokeWidth="3" strokeMiterlimit="10" x1="3.9" y1="226.3"
				x2="337.6" y2="387.7" />
			<line id="XMLID_3_" fill="none" stroke={fill} strokeWidth="3" strokeMiterlimit="10" x1="504.9" y1="5"
				x2="838.6" y2="166.4" />
		</svg>
	);
}

